import { deleteRejectedCookies } from '@utmb/design-system/types/cookie-consent-helper';
import { COOKIE_PRO_ID } from '../../constants/cookiePro';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { FC, useEffect } from 'react';

const CookiePro: FC = () => {
    const isDev = process.env.NODE_ENV === 'development';
    const router = useRouter();
    const isTest = router.isReady ? router.query.otpreview === 'true' : false;

    useEffect(() => {
        // Gérer la suppression des cookies lors du changement de consentement
        const handleConsentChange = () => {
            // Attendre un petit délai pour que OneTrust ait terminé ses opérations
            setTimeout(() => {
                deleteRejectedCookies();
            }, 500);
        };

        // Ajouter un écouteur pour l'événement OneTrust
        window.addEventListener('consent.onetrust', handleConsentChange);

        // Intercepter OptanonWrapper pour la suppression des cookies
        const originalOptanonWrapper = window.OptanonWrapper;
        window.OptanonWrapper = function () {
            if (originalOptanonWrapper) originalOptanonWrapper();
            handleConsentChange();
        };

        return () => {
            window.removeEventListener('consent.onetrust', handleConsentChange);
        };
    }, []);

    return (
        <>
            <Script
                async={false}
                src={`https://cdn.cookielaw.org/consent/${isDev ? '01953cc3-e2eb-74e2-b482-60d6cc3e7de8' : COOKIE_PRO_ID}${isTest ? '-test' : ''}/OtAutoBlock.js`}
                charSet={'UTF-8'}
            />
            <Script
                async={false}
                src={`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`}
                type="text/javascript"
                charSet={'UTF-8'}
                data-document-language="true"
                data-domain-script={`${isDev ? '01953cc3-e2eb-74e2-b482-60d6cc3e7de8' : COOKIE_PRO_ID}${isTest ? '-test' : ''}`}
            />
            <Script
                id="show-banner"
                dangerouslySetInnerHTML={{
                    __html: `${isTest ? 'console.log("mode preview OneTrust");' : ''}function OptanonWrapper()
                  {window.dataLayer.push({event:'OneTrustGroupsUpdated'})}`,
                }}
            />
        </>
    );
};

export default CookiePro;
